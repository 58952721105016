import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { faker } from '@faker-js/faker';
import axios from 'axios';
import React, { useState } from 'react';
// @mui
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import { styled } from '@mui/material/styles';
import { Button, Typography, Grid, Container, TextField, Stack, Snackbar, Box } from '@mui/material';
import useResponsive from '../hooks/useResponsive';
import DashboardPreview from "./landingpage.jpeg"
import FeedbacksDashboardPreview from "./feedbackspreview.png"
import CreateSessionPreview from "./sessionspreview.png"
import FeedbackPreview from "./feedbackpreview.png"
import JournalPreview from "./journalpreview.png"
import TennisCourtWait from "./courtwaiting.png"
import TournamentsPreview from "./tournamentspreview.jpeg"
import Iconify from '../components/iconify/Iconify';

import { AppOrderTimeline } from '../sections/@dashboard/app';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  minHeight: '70vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const features = [
  {
    icon: 'bi:journal-check',
    title: 'Comprehensive Match Logs',
    color: 'primary',
    description: 'Record detailed pre- and post-match reflections to track your progress and areas for improvement.',
  },
  {
    icon: 'iconamoon:trend-up',
    title: 'Performance Analytics',
    color: 'primary',
    description: 'Visualize your key stats and identify patterns in your play to enhance your strengths and work on weaknesses',
  },
  {
    icon: 'iconoir:stats-report',
    title: 'Goal Setting & Tracking',
    color: 'primary',
    description: 'Set measurable tennis goals and monitor your improvement over time with our intuitive tracking system.',
  },
  {
    icon: 'fa6-solid:bars-progress',
    title: 'Technique & Strategy Notes',
    color: 'primary',
    description: 'Document changes in your technique, successful strategies, and areas needing focus between matches and practice sessions.',
  },
  {
    icon: 'fluent:person-feedback-16-regular',
    title: 'Mental Game Journal',
    color: 'primary',
    description: 'Reflect on your mental state during matches and develop strategies to improve your on-court mindset.',
  },
  {
    icon: 'fluent:people-24-regular',
    title: 'Custom Training Log',
    color: 'primary',
    description: 'Keep a detailed record of your training sessions, drills, and fitness routines to optimize your practice time.',
  }
];

const howItWorksSteps = [
  {
    title: 'Sign Up',
    description: 'Create your Playgrade account by signing up with your email and password.',
  },
  {
    title: 'Analyze Matches',
    description: 'Upload your match videos or link your existing accounts for detailed analysis.',
  },
  {
    title: 'Improve Your Game',
    description: 'Receive personalized insights, track your progress, and elevate your tennis game.',
  },
];
// ----------------------------------------------------------------------

export const LandingPage = () => {
  const [emailAddress, setEmailAddress] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const isDesktop = useResponsive('up', 'lg');
  const [expandedTimeline, setExpandedTimeline] = useState(null);

  const handleTimelineClick = (index) => {
    setExpandedTimeline(expandedTimeline === index ? null : index);
  };

  const renderTimeline = (index, content) => (
    <Box key={index} onClick={() => handleTimelineClick(index)} sx={{ cursor: 'pointer', mb: 3 }}>
      <Timeline position="alternate">
        {content.map((step, stepIndex) => (
          <TimelineItem key={stepIndex}>
            <TimelineSeparator>
              <TimelineDot color={stepIndex === content.length - 1 ? 'success' : 'secondary'} />
              {stepIndex < content.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Typography>{step}</Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );

  const timelines = [
    ['Play a competitive match', 'Log session details on Playgrade', 'Get feedback from opponents/partners', 'Identify patterns and areas of focus', 'Use dashboard to crunch numbers', 'Improve your game'],
    ['Play a practice match', 'Log session details on Playgrade', 'Get feedback from opponents/partners', 'Performance in casual vs. serious matches', 'Use dashboard to crunch numbers', 'Improve your game'],
    ['Get a coaching session', 'Find drills on areas of weakness', 'Log drills and practice on Playgrade', 'Get feedback from coach', 'Compare match vs. coaching feedback', 'Improve your game'],
  ];
  const handleChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const predata = { "email": emailAddress, id: String(Math.random()) };
    const data = {
      body: JSON.stringify(predata),
      context: {},
    };

    const config = {
      method: "post",
      url: "https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/waitlist",
      headers: {
        "Content-Type": "text/plain",
      },
      data: predata,
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
    setShowSuccessMessage(true);
    setEmailAddress('');

  };

  const handleSnackbarClose = () => {
    setShowSuccessMessage(false);
  };
  return (
    <>
      <Helmet>
        <title> Unlock Your Tennis Potential with Playgrade's Digital Journal </title>
      </Helmet>

      <Container
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack
          direction={!isDesktop ? 'column' : 'row'}
          alignItems={!isDesktop ? 'stretch' : 'center'}
          justifyContent="space-between"
          sx={{ height: '60vh' }}
          spacing={!isDesktop ? 2 : 0}
        >
          <Box marginLeft={!isDesktop ? 0 : -10} marginRight={!isDesktop ? 0 : 10} sx={{ width: '100%', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }} paddingX={!isDesktop ? 5 : 0}>
            <StyledContent sx={{ textAlign: 'left', alignItems: 'center' }}>
              <Typography variant="h2" paragraph>
                Master Your Tennis Journey Through Journaling
              </Typography>

              <Typography sx={{ color: 'text.secondary', marginBottom: 3 }}>
                Elevate your game with Playgrade's comprehensive tennis journaling platform. Track progress, analyze performance, and achieve your goals on and off the court.
              </Typography>
              <Button variant="contained" to="/register" size="large" href="/register"
                startIcon={<Iconify icon="icon-park-twotone:experiment-one" width={20} height={20} />}>
                Try it for free
              </Button>
              <Snackbar
                open={showSuccessMessage}
                autoHideDuration={3000} // Adjust the duration as needed
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                message="You are on the waitlist!"
              />
            </StyledContent>
          </Box>
          {isDesktop && <Box
            component="img"
            src={DashboardPreview}
            marginLeft={isDesktop ? 10 : 0}
            marginRight={isDesktop ? -10 : 0}
            sx={{ position: 'relative', width: 'auto', height: '400px', borderRadius: '20px', boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}
          />}
        </Stack>
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
            mb: { xs: 5, md: 10 },
          }}
        >
          <Grid container spacing={3}
            marginTop={!isDesktop ? 10 : 0}
          >
            {features.map((feature, index) => (
              <Grid item key={index} xs={12} md={4}>
                <Box display="flex" flexDirection="column" alignItems="center"
                  sx={{
                    height: '100%', p: 3, border: "1px solid #e0e0e0",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", borderRadius: '20px'
                  }}
                >
                  <Box mb={2} sx={{ color: 'primary' }}><Iconify icon={feature.icon} width={36} height={36} /></Box>
                  <Typography variant="h6" component="h3" align="center" gutterBottom>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" align="center">
                    {feature.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ textAlign: 'center', mb: { xs: 5, md: 10 } }}>
          <Typography variant="h4" gutterBottom>
            What can you do with Playgrade?
          </Typography>
          <Stack
            direction={!isDesktop ? 'column' : 'row'}
            alignItems={!isDesktop ? 'stretch' : 'center'}
            justifyContent="space-between"
            spacing={!isDesktop ? 2 : 0}
          >
            <Box sx={{ width: '100%' }}>
              <Timeline position="alternate">
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    variant="body2"
                    color="text.secondary"
                  >
                    60-90 seconds
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                    <TimelineDot color="primary">
                      <Iconify icon="fluent:person-feedback-16-regular" />
                    </TimelineDot>
                    <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                      Detailed Journal
                    </Typography>
                    <Typography>Write down your thoughts, strategies and areas of focus before your match (or) coaching session and reflect on them after the session is over. </Typography>
                  </TimelineContent>
                  {isDesktop && <Box
                    component="img"
                    src={JournalPreview}
                    sx={{ position: 'relative', width: 'auto', height: '200px', borderRadius: '20px', boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}
                  />}
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                  >
                    30 seconds
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                    <TimelineDot color="primary">
                      <Iconify icon="tabler:report-search" />
                    </TimelineDot>
                    <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                      Log your session
                    </Typography>
                    <Typography>Record your competitive matches, practice matches, coaching sessions and what not.</Typography>
                  </TimelineContent>
                  {isDesktop && <Box
                    component="img"
                    src={CreateSessionPreview}
                    sx={{ position: 'relative', width: 'auto', height: '200px', borderRadius: '20px', boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}
                  />}
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    variant="body2"
                    color="text.secondary"
                  >
                    60-90 seconds
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                    <TimelineDot color="primary">
                      <Iconify icon="fluent:person-feedback-16-regular" />
                    </TimelineDot>
                    <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                      Peer and coach feedback
                    </Typography>
                    <Typography>Get feedback from your Opponents, Doubles partners or Coaches. Rate yourself against key metrics and compare them to what others have to say.</Typography>
                  </TimelineContent>
                  {isDesktop && <Box
                    component="img"
                    src={FeedbackPreview}
                    sx={{ position: 'relative', width: 'auto', height: '200px', borderRadius: '20px', boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}
                  />}
                </TimelineItem>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                    <TimelineDot color="primary">
                      <Iconify icon="carbon:improve-relevance" />
                    </TimelineDot>
                    <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                      Identify and Improve
                    </Typography>
                    <Typography>Compare various metrics like feedback, playtime, win %age across all the parameters you can imagine. Comprehensive filters and infographics at your service</Typography>
                  </TimelineContent>
                  {isDesktop && <Box
                    component="img"
                    src={FeedbacksDashboardPreview}
                    sx={{ position: 'relative', width: 'auto', height: '200px', borderRadius: '20px', boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}
                  />}
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    variant="body2"
                    color="text.secondary"
                  >
                    Coming soon
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot >
                      <Iconify icon="game-icons:tennis-court" />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                      Find courts near  you
                    </Typography>
                    <Typography>Don't want to wait 45 minutes to play a 1 hour game? Find courts near you with the lowest wait time</Typography>
                  </TimelineContent>
                  {isDesktop && <Box
                    component="img"
                    src={TennisCourtWait}
                    sx={{ position: 'relative', width: 'auto', height: '200px', borderRadius: '20px', boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}
                  />}
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                  >
                    Coming soon
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot >
                      <Iconify icon="mdi:tournament" />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6" component="span">
                      Tournaments
                    </Typography>
                    <Typography>Host and participate in Tennis tournaments around you. A fantastic way to improve your game and get to know other players near you</Typography>
                  </TimelineContent>
                  {isDesktop && <Box
                    component="img"
                    src={TournamentsPreview}
                    sx={{ position: 'relative', width: 'auto', height: '200px', borderRadius: '20px', boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}
                  />}
                </TimelineItem>
              </Timeline>
            </Box>
          </Stack>
        </Box>

        <Box sx={{ textAlign: 'center', mb: { xs: 5, md: 10 } }}>
          <Typography variant="h4" gutterBottom>
            Something for everyone
          </Typography>
          <Stack
            direction={!isDesktop ? 'column' : 'row'}
            alignItems={!isDesktop ? 'stretch' : 'center'}
            justifyContent="space-between"
            spacing={!isDesktop ? 2 : 0}
          >
            {timelines.map((content, index) => (
              <React.Fragment key={index}>
                {expandedTimeline === index ? renderTimeline(index, content) : renderTimeline(index, [content[0]] /* Show only the first part when not expanded */)}
              </React.Fragment>
            ))}
          </Stack>
        </Box>
      </Container>
      <footer
        style={{
          backgroundColor: '#f5f5f5',
          padding: '20px',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <Typography variant="body2" color="textSecondary">
          &copy; {new Date().getFullYear()} Playgrade. All rights reserved.
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <a href="mailto:contact@playgrade.app">contact@playgrade.app</a>
        </Typography>
      </footer>
    </>
  );
}
