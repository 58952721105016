import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { faker } from '@faker-js/faker';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { sentenceCase } from 'change-case';
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Grid,
  Avatar,
  Button,
  Popover,
  Checkbox,
  CircularProgress,
  TableRow,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Menu,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Chip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Modal from '@mui/material/Modal';
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { AppNewsUpdate } from '../sections/@dashboard/app';
import { AuthContext } from '../utils/auth';
import { WanderingAround } from './UnauthenticatedView';
import { invertScore } from './SelfFeedbackForm';
import { getUserByEmailOrPhone } from '../utils/getUser';
// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'winner', label: 'Winner', alignRight: false },
  { id: 'opponent', label: 'Opponent', alignRight: false },
  { id: 'coach', label: 'Coach', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'score', label: 'Performance', alignRight: false },
  { id: 'duration', label: 'Duration', alignRight: false },
  // { id: 'opponentFeedback', label: 'Opponent Feedback', alignRight: false },
  { id: 'feedbackv2', label: 'Feedback', alignRight: false }
];
const MAX_DISPLAYED_PLAYERS = 2;

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return filter(array, (row) => {
      const winners = row.players.filter(player => player.type === 'winner' || player.type === 'player');
      const opponents = row.players.filter(player => player.type === 'opponent');

      const winnerNames = winners.map(winner => winner.name.toLowerCase());
      const opponentNames = opponents.map(opponent => opponent.name.toLowerCase());

      return winnerNames.some(name => name.indexOf(query.toLowerCase()) !== -1) ||
        opponentNames.some(name => name.indexOf(query.toLowerCase()) !== -1);
    });
  }

  return stabilizedThis.map((el) => {
    const [row, index] = el;

    const winners = row.players.filter(player => player.type === 'winner' || player.type === 'player');
    const opponents = row.players.filter(player => player.type === 'opponent');

    const displayedWinners = winners.slice(0, MAX_DISPLAYED_PLAYERS);
    const displayedOpponents = opponents.slice(0, MAX_DISPLAYED_PLAYERS);
    const remainingWinnersCount = Math.max(0, winners.length - MAX_DISPLAYED_PLAYERS);
    const remainingOpponentsCount = Math.max(0, opponents.length - MAX_DISPLAYED_PLAYERS);

    return {
      ...row,
      displayedWinners,
      displayedOpponents,
      remainingWinnersCount,
      remainingOpponentsCount,
    };
  });
}




function listDifference(a, b) {
  const result = a.filter((element) => !b.includes(element));
  return result;
}

export default function UserPage() {
  const [open, setOpen] = useState(null);
  const [rowOpen, setRowOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [matches, setMatches] = useState([]);

  const [USERLIST, setUSERLIST] = useState([]);
  const [requestFeedbackPopupOpen, setRequestFeedbackPopupOpen] = useState(false);

  const [selfEvalOpen, setSelfEvalOpen] = useState(false);
  const [opponentEvalOpen, setOpponentEvalOpen] = useState(false);

  const feedbackOptions = ["Self", "Opponent", "Coach"]

  const [feedbacksForUser, setFeedbacksForUser] = useState({});
  const [userId, setUserId] = useState('');

  const defaultOptions = ["Limited", "Developing", "Average", "Proficient", "Outstanding"];
  const [openMenus, setOpenMenus] = useState({});
  const [feedbackSelectedOption, setFeedbackSelectedOption] = useState(null);


  const handleSelfEvalSubmit = (event) => {
    console.log(event)
    const predata = { ...selfFeedbackFormData, opponent: event.opponent, id: uuidv4(), playId: event.matchId, feedbackType: event.feedbackType }
    const config = {
      method: "post",
      url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/feedbacks`,
      headers: {
        "Content-Type": "text/plain",
      },
      data: predata,
    };

    axios(config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setSelfEvalOpen(false);
  };

  const [selfFeedbackFormData, setSelfFeedbackFormData] = useState({});
  const [oppoFeedbackFormData, setOppoFeedbackFormData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [feedbackAnchorEl, setFeedbackAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showAll, setShowAll] = useState(false);

  const renderPlayers = (players, isExpanded) => {
    const displayedPlayers = isExpanded ? players : players.slice(0, MAX_DISPLAYED_PLAYERS);
    const remainingPlayersCount = Math.max(0, players.length - MAX_DISPLAYED_PLAYERS);

    return (
      <span>
        {displayedPlayers.map((player, index) => (
          <React.Fragment key={index}>
            <Link
              to={`/v2/profile/${player.id}`}
              style={{
                textDecoration: 'none',
                color: player.id === userId ? 'gray' : 'inherit',
              }}
            >
              {player.name}
            </Link>
            {index < displayedPlayers.length - 1 && <br />} {/* Line break between players */}
          </React.Fragment>
        ))}
        {!isExpanded && remainingPlayersCount > 0 && (
          <span style={{ color: 'gray' }}> + {remainingPlayersCount} more</span>
        )}
      </span>
    );
  };

  const handleFeedbackMenuOpen = (event, row) => {
    setFeedbackAnchorEl(event.currentTarget)
    setOpenMenus({ ...openMenus, [row.id]: true });
  };

  const handleFeedbackMenuClose = (row) => {
    setFeedbackAnchorEl(null)
    setOpenMenus({ ...openMenus, [row.id]: false });
  };


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setAnchorEl(null);
    if (option === 'competitiveMatch') {
      window.location.href = '/v2/sessions/create/competitive';
    } else if (option === 'practiceMatch') {
      window.location.href = '/v2/sessions/create/practice';
    } else if (option === 'drills') {
      window.location.href = '/v2/sessions/create/training';
    }
  };

  const handleFeedbackMenuOptionClick = (option, id) => {
    setFeedbackSelectedOption(option);

    switch (option) {
      case 'Self':
        window.location.href = `/v2/feedback/self/new/${id}`;
        break;
      default:
        window.location.href = `/v2/feedback/self/new/${id}`;
    }
    handleFeedbackMenuClose();
  }

  const handleExistingFeedbackOpen = (id) => {
    window.location.href = `/v2/feedback/${id}`
  }

  const handleFeedbackScreenOpen = (playId) => {
    window.location.href = `/v2/feedback/${playId}`
  }

  const handleFeedbackFormChange = (event) => {
    if (feedbackOpenMetadata.type === "self") {
      setSelfFeedbackFormData({
        ...selfFeedbackFormData,
        [event.id]: event.option,
      });
    }
    else {
      setOppoFeedbackFormData({
        ...oppoFeedbackFormData,
        [event.id]: event.option,
      });
    }
  };
  const [feedbackOpenMetadata, setFeedbackOpenMetadata] = useState({ "type": "", "matchId": "" });
  const [selfFeedbackExists, setSelfFeedbackExists] = useState(false);

  const handleSelfEvalOpen = (matchId) => {
    setFeedbackOpenMetadata({ "type": "self", "matchId": matchId });
    setSelfEvalOpen(true);
  };

  const handleOppoEvalOpen = (matchId) => {
    setFeedbackOpenMetadata({ "type": "opponent", "matchId": matchId });
    setOpponentEvalOpen(true);
  };

  const handleSelfEvalClose = () => {
    setSelfEvalOpen(false);
  };

  const handleRequestFeedbackPopupClose = () => {
    setRequestFeedbackPopupOpen(false);
  };

  const handleRequestFeedbackPopupOpen = (matchId) => {
    const opponentFeedbackLink = `${window.location.host}/v2/sessions/feedback/${matchId}`;
    navigator.clipboard.writeText(opponentFeedbackLink);
    setRequestFeedbackPopupOpen(true);
  };

  const handleOppoEvalClose = () => {
    setOpponentEvalOpen(false);
  };

  useEffect(() => {
    const getFeedbackByMatchId = (matchId) => {
      const config = {
        method: "get",
        url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/feedbacks/plays/${matchId}`,
        headers: {
          "Content-Type": "application/json"
        },
      };
      axios(config)
        .then((response) => {
          if (response.data.length > 0) {
            setSelfFeedbackExists(true);
            setSelfFeedbackFormData(response.data[0]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (feedbackOpenMetadata.matchId !== "") {
      getFeedbackByMatchId(feedbackOpenMetadata.matchId);
    }
  }, [feedbackOpenMetadata]);

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    async function fetchData() {
      const getFeedbacksForUser = async () => {
        const userIdFromResponse = await getUserByEmailOrPhone(currentUser);
        const config = {
          method: "get",
          url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/feedbacks/users/${userIdFromResponse}`,
          headers: {
            "Content-Type": "application/json"
          },
        };

        axios(config)
          .then((response) => {
            const data = response.data;
            const feedbacks = {};
            data.forEach((feedback) => {
              feedbacks[feedback.playId] = true;
            });
            setFeedbacksForUser(feedbacks);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      const fetchMatches = async () => {
        try {
          const userIdFromResponse = await getUserByEmailOrPhone(currentUser);
          setUserId(userIdFromResponse)
          const response = await axios.get(`https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/plays/users/${userIdFromResponse}`);
          setMatches(response.data);
          setUSERLIST(response.data);
        } catch (error) {
          console.log(error);
        }
      };
      try {
        setIsLoading(true)
        if (currentUser) {
          await getFeedbacksForUser();
          await fetchMatches();
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error)
        setIsLoading(false)
      }
    }
    fetchData();
  }, []);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.opponent);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    height: 180,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'scroll'
  };

  if (!currentUser) {
    return <WanderingAround />;
  }

  const renderMenuItem = (option, label, tagLabel, tagColor, disabled = false) => (
    <MenuItem disabled={disabled} onClick={() => handleOptionClick(option)}>
      <Chip label={tagLabel} color={tagColor} size="small" sx={{ mr: 4 }} />
      <Typography sx={{ flexGrow: 1, textAlign: 'right', lineHeight: 2 }}>{label}</Typography>
    </MenuItem>
  );

  return (
    <>
      <Helmet>
        <title> Sessions | Playgrade </title>
      </Helmet>

      <Container>
        {isLoading ? (
          // Render loading indicator
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 'calc(100vh - 64px)', // Adjust this value based on your layout
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
              <Typography variant="h4" gutterBottom>
                Sessions
              </Typography>

              {/* New Session button with the menu */}
              <Button
                id="new-session-button"
                variant="contained"
                onClick={handleMenuOpen}
              >
                + New
              </Button>


              {/* Menu */}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'new-session-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{ mt: 1 }}
                getContentAnchorEl={null} // Prevents the menu from covering the button
              >
                {renderMenuItem('competitiveMatch', 'Competition', 'Match', 'primary')}
                {renderMenuItem('practiceMatch', 'Casual', 'Match', 'primary', false)}
                {/* {renderMenuItem('coachingSession', 'Guided', 'Training', 'error', true)} */}
                {renderMenuItem('drills', 'Drills', 'Training', 'error', false)}
                {/* Add more menu items with tags/chips here */}
              </Menu>
            </Stack>

            <Card>
              <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={USERLIST.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                        const { id, opponent, location, durationHours, durationMinutes, score, matchType, feedback } = row;
                        const selectedUser = selected.indexOf(opponent) !== -1;
                        return (
                          <>
                            <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                              <TableCell padding="checkbox">
                                {row.opponentId !== userId && <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => setOpen((prevOpen) => (prevOpen === id ? null : id))}
                                >
                                  {open === id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>}
                              </TableCell>
                              <TableCell align="left">
                                <span
                                  role="button"
                                  tabIndex={0}
                                  style={{ cursor: 'pointer', color: 'inherit' }}
                                  onClick={() => setOpen((prevOpen) => (prevOpen === id ? null : id))}
                                  onKeyDown={(e) => e.key === 'Enter' && setOpen((prevOpen) => (prevOpen === id ? null : id))}
                                >
                                  {renderPlayers(row.players.filter(player => player.type === 'winner' || player.type === 'player'), open === id)}
                                </span>
                              </TableCell>

                              {row.players.find(player => player.type === 'opponent') ? (
                                <TableCell align="left">
                                  {/* Use Link component to create a hyperlink */}
                                  <Link
                                    to={`/v2/profile/${row.players.find(player => player.type === 'opponent').id}`}
                                    style={{
                                      textDecoration: 'none',
                                      color: row.players.find(player => player.type === 'opponent').id === userId ? 'gray' : 'inherit'
                                    }}
                                  >
                                    {row.players.find(player => player.type === 'opponent').name}
                                  </Link>
                                </TableCell>
                              ) : (
                                <TableCell align="left" />
                              )}

                              {row.coaches && (
                                <TableCell align="left">
                                  {row.coaches.length > 0 ? (
                                    <Link
                                      to={`/v2/profile/${row.coaches[0].id}`}
                                      style={{
                                        textDecoration: 'none',
                                      }}
                                    >
                                      {row.coaches[0].name}
                                    </Link>
                                  ) : null}
                                </TableCell>
                              )}

                              <TableCell align="left">{matchType}</TableCell>

                              <TableCell align="left">
                                {row.drills && row.drills.length > 0
                                  ? row.drills.map((drill) => drill.label).join(', ')
                                  : row.score}
                              </TableCell>

                              <TableCell align="left">{durationHours} hrs {durationMinutes} mins</TableCell>

                              {/* <TableCell align="left">
                            { feedbacksForUser[id] && 
                              <Label marginLeft={6} sx={{cursor: 'pointer'}} color={'success'} onClick={()=>{window.location.href=`/v2/sessions/feedback/${id}`}}>View</Label>
                            }
                            { !feedbacksForUser[id] &&
                              <Label marginLeft={6} sx={{cursor: 'pointer'}} color={'error'} onClick={()=> handleRequestFeedbackPopupOpen(id)}>Request</Label>
                            }
                          <Modal
                            keepMounted
                            open={requestFeedbackPopupOpen}
                            onClose={handleRequestFeedbackPopupClose}
                            aria-labelledby="keep-mounted-modal-title"
                            aria-describedby="keep-mounted-modal-description"
                          >
                            <Box sx={modalStyle}>
                              <Typography variant="h6" gutterBottom>
                                Link copied!
                              </Typography>

                              <Typography variant="body2" gutterBottom>
                                Since your opponent is not a registered user, you can share this link with them to request feedback.
                              </Typography>
                            </Box>
                          </Modal>   */}
                              {/* <Label sx={{cursor: 'pointer'}} color={'success'} onClick={() => handleSelfEvalOpen(id)}>Self</Label>
                          <Modal
                            keepMounted
                            open={selfEvalOpen}
                            onClose={handleSelfEvalClose}
                            aria-labelledby="keep-mounted-modal-title"
                            aria-describedby="keep-mounted-modal-description"
                          >
                            <Box sx={modalStyle}>
                              <AppNewsUpdate
                                title="Evaluate Yourself"
                                submitHandler={handleSelfEvalSubmit}
                                opponent={opponent}
                                matchId={id}
                                feedbackType="self"
                                exists={selfFeedbackExists}
                                list={feedbackFormData.map((item, index) => ({
                                  id: item.key,
                                  title: item.name,
                                  options: item.options,
                                  handleChange : handleFeedbackFormChange,
                                  values: selfFeedbackFormData
                                }))}
                              />
                            </Box>
                          </Modal>
                          <Label sx={{cursor: 'pointer'}} color={'error'}>Opponent</Label> */}
                              {/* </TableCell> */}
                              <TableCell>
                                {/* <Label
                          sx={{ cursor: 'pointer' }}
                          color={'info'}
                          onClick={(event) => handleFeedbackMenuOpen(event, row)} // Pass the row to the handler
                        >
                          <Iconify 
                            icon={'ic:baseline-plus'} 
                            sx={{height:0.7}}
                          />
                        </Label> */}
                                <Label
                                  sx={{ cursor: 'pointer', marginLeft: 1 }}
                                  color={'info'}
                                  onClick={() => handleExistingFeedbackOpen(id)}
                                >
                                  <Iconify
                                    icon={'fluent:open-24-filled'}
                                    sx={{ height: 0.7 }}
                                  />
                                </Label>
                                {/* {feedback && feedback.map((item) => (
                          <Label
                            key={item.type} // Don't forget to add a unique key when mapping in React
                            sx={{ cursor: 'pointer' }}
                            marginLeft={1}
                            color={'info'}
                            onClick={() => handleExistingFeedbackOpen(item.id)}
                          >
                            {item.type}
                          </Label>
                        ))} */}
                                {/* <Menu
                          anchorEl={feedbackAnchorEl}
                          open={openMenus[row.id] || false} // Check if the menu for this row should be open
                          onClose={() => handleFeedbackMenuClose(row)} // Pass the row to the handler to close the correct menu
                        >
                          {listDifference(feedbackOptions, (feedback || []).map((obj)=> obj.type)).map((option) => (
                            <MenuItem
                            onClick={() => handleFeedbackMenuOptionClick(option,id)}
                            sx={{ fontSize: '12px' }} // Adjust the font size as needed
                          >
                            {option}
                          </MenuItem>
                          ))}
                        </Menu> */}
                              </TableCell>


                              {/* <TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell> */}
                            </TableRow>
                            {row.opponentId !== userId && <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <Collapse in={open === id} timeout="auto" unmountOnExit>
                                  <Box sx={{ margin: 1 }}>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
                                      Notes
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                      {row.notes}
                                    </Typography>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>}
                          </>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>


                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={USERLIST.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </>
        )}
      </Container>

      {/* <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover> */}
    </>
  );
}
