import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga';
import {
    Card,
    Table,
    Stack,
    Paper,
    Button,
    MenuItem,
    Menu,
    TableBody,
    TableCell,
    TableRow,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Chip,
    CircularProgress,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { AuthContext } from '../utils/auth';
import { WanderingAround } from './UnauthenticatedView';
import { getAllTournaments } from '../api/ApiGateway';
import { formatDate } from './TournamentRegistrationPage';
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import Scrollbar from '../components/scrollbar';

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'locationName', label: 'Venue', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
];

const MAX_DISPLAYED_PLAYERS = 2;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    if (query) {
        return filter(array, (row) => {
            const winners = row.players?.filter(player => player.type === 'winner' || player.type === 'player') || [];
            const opponents = row.players?.filter(player => player.type === 'opponent') || [];

            const winnerNames = winners.map(winner => winner.name.toLowerCase());
            const opponentNames = opponents.map(opponent => opponent.name.toLowerCase());

            return winnerNames.some(name => name.includes(query.toLowerCase())) ||
                opponentNames.some(name => name.includes(query.toLowerCase()));
        });
    }

    return stabilizedThis.map((el) => {
        const [row] = el;

        const winners = row.players?.filter(player => player.type === 'winner' || player.type === 'player') || [];
        const opponents = row.players?.filter(player => player.type === 'opponent') || [];

        const displayedWinners = winners.slice(0, MAX_DISPLAYED_PLAYERS);
        const displayedOpponents = opponents.slice(0, MAX_DISPLAYED_PLAYERS);
        const remainingWinnersCount = Math.max(0, winners.length - MAX_DISPLAYED_PLAYERS);
        const remainingOpponentsCount = Math.max(0, opponents.length - MAX_DISPLAYED_PLAYERS);

        return {
            ...row,
            displayedWinners,
            displayedOpponents,
            remainingWinnersCount,
            remainingOpponentsCount,
        };
    });
}


export default function TournamentListPage() {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [tournamentList, setTournamentList] = useState([]);

    const { currentUser } = useContext(AuthContext);

    useEffect(() => {
        setIsLoading(true);
        ReactGA.pageview(window.location.pathname + window.location.search);
        const fetchData = async () => {
            try {
                const response = await getAllTournaments();
                setTournamentList(response.data);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tournamentList.length) : 0;

    const filteredUsers = applySortFilter(tournamentList, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;

    if (!currentUser) {
        return <WanderingAround />;
    }

    return (
        <>
            <Helmet>
                <title> Tournaments | Playgrade </title>
            </Helmet>

            <Container>
                {isLoading ? (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: 'calc(100vh - 64px)',
                        }}
                    >
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                            <Typography variant="h4" gutterBottom>
                                Tournaments
                            </Typography>
                        </Stack>

                        <Card>
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <UserListHead
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={TABLE_HEAD}
                                            rowCount={tournamentList.length}
                                            numSelected={selected.length}
                                            onRequestSort={handleRequestSort}
                                            showCheckBox={false}
                                        />
                                        <TableBody>
                                            {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                                const { tournamentName, courtName, startDateTime, id } = row;
                                                return (
                                                    <TableRow hover key={id} tabIndex={-1}>
                                                        <TableCell align="left">
                                                            {tournamentName}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {courtName}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {formatDate(startDateTime)}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Button
                                                                variant="contained"
                                                                component={Link}
                                                                to={`/v2/tournaments/${id}/register`}
                                                            >
                                                                View
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 53 * emptyRows }}>
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>

                                        {isNotFound && (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                        <Paper
                                                            sx={{
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            <Typography variant="h6" paragraph>
                                                                Not found
                                                            </Typography>

                                                            <Typography variant="body2">
                                                                No results found for &nbsp;
                                                                <strong>&quot;{filterName}&quot;</strong>.
                                                                <br /> Try checking for typos or using complete words.
                                                            </Typography>
                                                        </Paper>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={tournamentList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Card>
                    </>
                )}
            </Container>
        </>
    );
}
