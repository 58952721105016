import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import dayjs, { Dayjs } from 'dayjs';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Container,
  Typography,
  Stack,
  Button,
  CircularProgress,
  TextField,
  Grid,
  MenuItem,
  Modal,
  Rating,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  createFilterOptions
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ReactGA from 'react-ga';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { AuthContext } from '../utils/auth';
import Label from '../components/label';
import Iconify from '../components/iconify';
import allCourtsData from '../utils/tln_allcourts.json'
import useResponsive from '../hooks/useResponsive';
import { createJournalEntry, getJournalEntryById, updateJournalEntryById, createUser, createCourtLocation, getUserCreatedCourts, createPlay } from "../api/ApiGateway"
import { getUserByEmailOrPhone } from '../utils/getUser';

const filter = createFilterOptions();

const drillOptions = [
  { id: 'forehand', label: 'Forehand', description: 'Improve your forehand technique' },
  { id: 'backhand', label: 'Backhand', description: 'Improve your backhand technique' },
  { id: 'stamina', label: 'Stamina', description: 'Various exercises to improve stamina' },
  { id: 'volley', label: 'Volley', description: 'Training closer to the net' },
];

const shotPatternOptions = [
  { id: 'crossCourtForehand', label: 'Cross-Court Forehand', description: 'Wide shot across the court' },
  { id: 'downTheLineForehand', label: 'Down-the-Line Forehand', description: 'Straight shot along sideline' },
  { id: 'crossCourtBackhand', label: 'Cross-Court Backhand', description: 'Wide backhand across the court' },
  { id: 'downTheLineBackhand', label: 'Down-the-Line Backhand', description: 'Straight backhand along sideline' },
  { id: 'topspinForehand', label: 'Topspin Forehand', description: 'Forward spin, high bouncing shot' },
  { id: 'topspinBackhand', label: 'Topspin Backhand', description: 'High bouncing backhand with spin' },
  { id: 'sliceBackhand', label: 'Slice Backhand', description: 'Low, skidding backhand shot' },
  { id: 'dropShot', label: 'Drop Shot', description: 'Soft, short shot over net' },
  { id: 'lob', label: 'Lob', description: 'High, deep shot over opponent' },
  { id: 'overheadSmash', label: 'Overhead Smash', description: 'Powerful shot from overhead' },
  { id: 'volley', label: 'Volley', description: 'Quick shot before ball bounces' },
  { id: 'halfVolley', label: 'Half-Volley', description: 'Shot right after ball bounces' },
  { id: 'serve', label: 'Serve', description: 'Initial shot to start a point' },
  { id: 'kickServe', label: 'Kick Serve', description: 'High bouncing serve with topspin' },
  { id: 'flatServe', label: 'Flat Serve', description: 'Fast, straight serve with minimal spin' },
  { id: 'sliceServe', label: 'Slice Serve', description: 'Serve with side spin, curving' },
  { id: 'insideOutForehand', label: 'Inside-Out Forehand', description: 'Forehand hit from backhand side' },
  { id: 'insideInForehand', label: 'Inside-In Forehand', description: 'Forehand hit down the line' },
  { id: 'runningForehand', label: 'Running Forehand', description: 'Forehand hit on the run' },
  { id: 'runningBackhand', label: 'Running Backhand', description: 'Backhand hit on the run' },
  { id: 'passingShot', label: 'Passing Shot', description: 'Shot to pass opponent at net' },
  { id: 'moonball', label: 'Moonball', description: 'Very high, deep defensive shot' }
];

const labels = {
  0: 'Much Worse',
  0.5: 'Worse',
  1: 'Bit Worse',
  1.5: 'Slightly Worse',
  2: 'Could Improve',
  2.5: 'As Expected',
  3: 'Fine',
  3.5: 'Decent',
  4: 'Better',
  4.5: 'Great',
  5: 'Excellent',
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  height: 180,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll'
};



function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

export function HoverRating({ name, patternId, shotPatternRatings = {}, setShotPatternRatings }) {
  const [hover, setHover] = React.useState(-1);

  const handleRatingChange = (event, newValue) => {
    setShotPatternRatings((prevRatings) => ({
      ...prevRatings,
      [patternId]: newValue,
    }));
  };

  const currentValue = patternId in shotPatternRatings ? shotPatternRatings[patternId] : 2.5;

  return (
    <>
      <Typography variant="body2">{name}</Typography>
      <Box
        width={300}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Rating
          name="hover-feedback"
          value={currentValue}
          precision={0.5}
          getLabelText={getLabelText}
          onChange={handleRatingChange}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
        {currentValue !== null && (
          <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : currentValue]}</Box>
        )}
      </Box>
    </>
  );
}


function getTennisMatchResult(score) {
  const sets = score.split(',');

  let player1SetsWon = 0;
  let player2SetsWon = 0;

  sets.forEach(set => {
    const [gamesPlayer1, gamesPlayer2] = set.split('-').map(Number);

    if (gamesPlayer1 > gamesPlayer2) {
      player1SetsWon += 1;
    } else if (gamesPlayer2 > gamesPlayer1) {
      player2SetsWon += 1;
    }
    // If games are equal, no winner for the set
  });

  if (player1SetsWon > player2SetsWon) {
    return {
      winner: 0, // Player 1 wins
      score
    };
  }

  if (player2SetsWon > player1SetsWon) {
    // Player 2 wins, flip the score
    const flippedScore = sets.map(set => {
      const [gamesPlayer1, gamesPlayer2] = set.split('-').map(Number);
      return `${gamesPlayer2}-${gamesPlayer1}`;
    }).reverse().join(',');

    return {
      winner: 1, // Player 2 wins
      score: flippedScore
    };
  }

  // Draw, both players won the same number of sets
  return {
    winner: -1,
    score
  };
}

const focusAreasOptions = ['Technical', 'Mental', 'Tactical', 'Emotional'];


export default function JournalPage(props) {
  const { journalId } = useParams();
  document.title = 'Journal | Playgrade';
  ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname + window.location.search,
  });
  const isDesktop = useResponsive('up', 'lg');
  const [opponent, setOpponent] = useState('');
  const [players, setPlayers] = useState([]);
  const [opponents, setOpponents] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [score, setScore] = useState('');
  const [durationHours, setDurationHours] = useState('');
  const [durationMinutes, setDurationMinutes] = useState('');
  const [courtType, setCourtType] = useState('');
  const [courtLocation, setCourtLocation] = useState(null);
  const [datetime, setDatetime] = useState(dayjs());
  const [time, setTime] = useState(new Date());
  const [notes, setNotes] = useState('');
  const [matchType, setMatchType] = useState(props.type || 'Competitive');
  const [tags, setTags] = useState([]);
  const [shotPatterns, setShotPatterns] = useState([]);
  const [shotPatternRatings, setShotPatternRatings] = useState({});
  const [loggedInUserId, setLoggedInUserId] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [focusAreas, setFocusAreas] = useState([]);
  const [focusAreaNotes, setFocusAreaNotes] = useState({});
  const [numberOfAces, setNumberOfAces] = useState('');
  const [gameProgression, setGameProgression] = useState('');
  const [enablePostMatchSection, setEnablePostMatchSection] = useState(false);
  const [strategies, setStrategies] = useState([]);
  const [strategyNotes, setStrategyNotes] = useState({});
  const [allCourtsDataFromLocal, setAllCourtsDataFromLocal] = useState([]);


  const handleCourtTypeChange = (event, value) => {
    setCourtType(value);
  };

  const handlePostMatchsectionToggle = () => {
    setEnablePostMatchSection(!enablePostMatchSection);
  };

  const handlePlayerChange = (event, newPlayers) => {
    setPlayers(newPlayers);
  };

  const handleOpponentsChange = (event, newOpponents) => {
    setOpponents(newOpponents);
  };

  const handleCoachesChange = (event, newCoaches) => {
    setCoaches(newCoaches);
  };

  const handleScoreChange = (event) => {
    setScore(event.target.value);
  };

  const handleGameProgressionChange = (event) => {
    setGameProgression(event.target.value);
  };

  const handleNumberOfAcesChange = (event) => {
    setNumberOfAces(event.target.value);
  };

  const handleDurationHoursChange = (event) => {
    setDurationHours(event.target.value);
  };

  const handleDurationMinutesChange = (event) => {
    setDurationMinutes(event.target.value);
  };

  const handleCourtLocationChange = (event, value) => {
    setCourtLocation(value);
  };

  const handleDateChange = (newDate) => {
    setDatetime(newDate);
  };

  const handleTimeChange = (newTime) => {
    setTime(newTime);
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleTagsChange = (event, value) => {
    setTags(value);
  };

  const handleShotPatternsChange = (event, value) => {
    setShotPatterns(value);
  };

  const handleFocusAreasChange = (event, newFocusAreas) => {
    setFocusAreas(newFocusAreas);
  };

  const handleFocusAreaNotesChange = (focusArea, notes) => {
    setFocusAreaNotes((prevNotes) => ({
      ...prevNotes,
      [focusArea]: notes,
    }));
  };

  const handleStrategiesChange = (event, newStrategies) => {
    setStrategies(newStrategies);
  };

  const handleStrategyNotesTitleChange = (index, title) => {
    setStrategyNotes((prevNotes) => ({
      ...prevNotes,
      [index]: { ...prevNotes[index], title },
    }));
  };

  const handleStrategyNotesFeedbackChange = (index, feedback) => {
    setStrategyNotes((prevNotes) => ({
      ...prevNotes,
      [index]: { ...prevNotes[index], feedback },
    }));
  };

  const handleStrategyNotesFollowupChange = (index, followup) => {
    setStrategyNotes((prevNotes) => ({
      ...prevNotes,
      [index]: { ...prevNotes[index], followup },
    }));
  };

  const handleAddStrategy = () => {
    setStrategies((prevStrategies) => [...prevStrategies, prevStrategies.length]);
    setStrategyNotes((prevNotes) => ({
      ...prevNotes,
      [strategies.length]: {
        title: '',
        feedback: '',
        followup: '',
      },
    }));
  };

  const handleFocusAreasTitleChange = (index, title) => {
    setFocusAreaNotes((prevNotes) => ({
      ...prevNotes,
      [index]: { ...prevNotes[index], title },
    }));
  };

  const handleFocusAreaNotesFeedbackChange = (index, feedback) => {
    setFocusAreaNotes((prevNotes) => ({
      ...prevNotes,
      [index]: { ...prevNotes[index], feedback },
    }));
  };

  const handleFocusAreaNotesFollowupChange = (index, followup) => {
    setFocusAreaNotes((prevNotes) => ({
      ...prevNotes,
      [index]: { ...prevNotes[index], followup },
    }));
  };

  const handleAddFocusArea = () => {
    setFocusAreas((prevStrategies) => [...prevStrategies, prevStrategies.length]);
    setFocusAreaNotes((prevNotes) => ({
      ...prevNotes,
      [focusAreas.length]: {
        title: '',
        feedback: '',
        followup: '',
      },
    }));
  };

  useEffect(() => {
    console.log('strategies', strategies)
    console.log('strategy notes', strategyNotes)
  }, [strategies, strategyNotes])

  const { currentUser } = useContext(AuthContext);
  const userEmail = currentUser.email;
  const userName = currentUser.displayName;
  const [allUsers, setAllUsers] = useState([])
  const [isCoachMode, setIsCoachMode] = useState(false);
  const [isPlayersValid, setIsPlayersValid] = useState(true);
  const [isOpponentsValid, setIsOpponentsValid] = useState(true);
  const [player, setPlayer] = useState('');
  const [user, setUser] = useState({});
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const [isExistingJournalEntry, setIsExistingJournalEntry] = useState(false);
  const [existingJournalEntry, setExistingJournalEntry] = useState({});
  const [playersInputValue, setPlayersInputValue] = useState('');
  const [opponentsInputValue, setOpponentsInputValue] = useState('');
  const [coachesInputValue, setCoachesInputValue] = useState('');
  const [courtLocationInputValue, setCourtLocationInputValue] = useState('');
  const [requestFeedbackPopupOpen, setRequestFeedbackPopupOpen] = useState(false);

  const handleRequestFeedbackPopupClose = () => {
    setRequestFeedbackPopupOpen(false);
  };

  const handleRequestFeedbackPopupOpen = (matchId) => {
    setRequestFeedbackPopupOpen(true);
  };

  useEffect(() => {
    console.log('coaches', allUsers)
  }, [allUsers])

  useEffect(() => {
    const fetchData = async () => {
      if (journalId) {
        const data = await getJournalEntryById(journalId)
        if (data) {
          setPlayers(data.data.players);
          setOpponents(data.data.opponents);
          setCoaches(data.data.coaches);
          setDatetime(dayjs(data.data.sessionDateTime));
          setCourtType(data.data.courtType);
          setCourtLocation(allCourtsData.find((court) => court.id === data.data.courtId) || null);
          setScore(data.data.score);
          setDurationHours(data.data.durationHours);
          setDurationMinutes(data.data.durationMinutes);
          setIsExistingJournalEntry(true);
          setExistingJournalEntry(data.data);
          setShotPatterns(data.data.shotPatterns);
          setShotPatternRatings(data.data.shotPatternRatings);
          setFocusAreas(Object.keys(data.data.focusAreaNotes).map(Number));
          setFocusAreaNotes(data.data.focusAreaNotes);
          setNotes(data.data.notes);
          setTags(data.data.tags);
          setEnablePostMatchSection(data.data.enablePostMatchSection);
          setStrategyNotes(data.data.strategyNotes);
          setStrategies(Object.keys(data.data.strategyNotes).map(Number));
          setIsLoading(false);
          setNumberOfAces(data.data.numberOfAces);
        }
      }
    }
    setIsLoading(true)
    fetchData();
    setIsLoading(false)
  }, [journalId, allCourtsData])

  useEffect(() => {
    const loadLocalCourtData = async () => {
      const userCreatedCourts = await getUserCreatedCourts();
      console.log('userCreatedCourts', userCreatedCourts);
      console.log('allCourtsData', allCourtsData.slice(0, 10));
      const combinedCourtData = [...allCourtsData, ...userCreatedCourts.data];
      console.log('combinedCourtData', combinedCourtData.slice(0, 10));
      setAllCourtsDataFromLocal(combinedCourtData);
    };
    loadLocalCourtData();
  }, []);

  useEffect(() => {
    console.log('courtLocation', courtLocation)
  }, [courtLocation])

  const handleCoachModeToggle = () => {
    setIsCoachMode(!isCoachMode);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const userId = await getUserByEmailOrPhone(currentUser);
        console.log('user id is', userId);
        setLoggedInUserId(userId);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false)
      }
      finally {
        setIsLoading(false)
      }
    };

    fetchData(); // Call the async function immediately

  }, [currentUser, loggedInUserId]);

  useEffect(() => {
    if (!isCoachMode) {
      // Set the default player value to [{ id: loggedInUserId, displayName: userName }]
      setPlayers([
        {
          id: loggedInUserId,
          displayName: userName,
        },
      ]);
    } else {
      setPlayers([]); // Clear players in coach mode
      setOpponents([]); // Clear opponents in coach mode
    }
  }, [isCoachMode, loggedInUserId, userName]);

  useEffect(() => {
    if (!isCoachMode) {
      // Set the default player value to { id: loggedInUserId, displayName: userName }
      setPlayer({
        id: loggedInUserId,
        displayName: user.displayName,
      });
    }
    else {
      setPlayer('');
    }
  }, [isCoachMode, loggedInUserId, user]);


  useEffect(() => {
    let cancelRequest = null;

    const getData = async () => {
      try {
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'X-API-Key': '2LnZuXL3eoai8wCLbaQ8O67ZEghqX5C07e2uSMQE'
        };

        // Create a cancel token
        const source = axios.CancelToken.source();

        // Assign the cancel token to the cancelRequest variable
        cancelRequest = source;

        // const courtResponse = await axios.get(`https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/courts`, {
        //   cancelToken: source.token,
        // });

        // setallCourtLocations(courtResponse.data);
        const userResponse = await axios.get(`https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/users`, {
          cancelToken: source.token,
        });

        setAllUsers(userResponse.data);
        setIsLoading(false);
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled, do nothing.
        } else {
          console.log(error);
        }
        setIsLoading(false);
      }
    };
    setIsLoading(true);

    // Call the async function inside the effect.
    getData();
    setIsLoading(false);

    // Clean up function: Cancel the request when the component unmounts.
    return () => {
      if (cancelRequest) {
        cancelRequest.cancel('Request canceled due to component unmount.');
      }
    };
  }, []);

  const handleSubmit = async (event) => {
    setIsFormSubmitting(true);
    // setIsLoading(true);
    event.preventDefault();
    console.log('opponents', opponents)
    if (!players || players.length === 0) {
      // Set the state to indicate that players are not valid
      setIsPlayersValid(false);
      return;
    }
    setIsOpponentsValid(true);
    setIsPlayersValid(true);

    const cleanedPlayersAwaits = players.map(async (player) => {
      if (player.newUser) {
        const newUserId = uuidv4();
        const userDto = {
          id: newUserId,
          displayName: player.inputValue,
        }
        await createUser({ ...userDto, createdByUserId: loggedInUserId });
        return userDto;
      }

      return player;
    });

    const cleanedPlayers = await Promise.all(cleanedPlayersAwaits);

    const cleanedOpponentsAwaits = opponents.map(async (opponent) => {
      if (opponent.newUser) {
        const newUserId = uuidv4();
        const userDto = {
          id: newUserId,
          displayName: opponent.inputValue,
        }
        await createUser({ ...userDto, createdByUserId: loggedInUserId });
        return userDto;
      }

      return opponent;
    });

    const cleanedOpponents = await Promise.all(cleanedOpponentsAwaits);

    const cleanedCoachesAwaits = coaches.map(async (coach) => {
      if (coach.newUser) {
        const newUserId = uuidv4();
        const userDto = {
          id: newUserId,
          displayName: coach.inputValue,
        }
        await createUser({ ...userDto, createdByUserId: loggedInUserId });
        return userDto;
      }

      return coach;
    });

    const cleanedCoaches = await Promise.all(cleanedCoachesAwaits);

    await createCourtLocation(courtLocation)

    const newJournalEntryData = {
      sessionDateTime: datetime,
      courtLocation: { courtLocation }["Location Name"],
      courtId: courtLocation.id,
      courtType,
      tags,
      shotPatterns,
      shotPatternRatings,
      score,
      durationHours,
      durationMinutes,
      focusAreas,
      focusAreaNotes,
      notes,
      createdByUserId: loggedInUserId,
      players: cleanedPlayers,
      opponents: cleanedOpponents,
      coaches: cleanedCoaches,
      enablePostMatchSection,
      strategyNotes,
      numberOfAces,
    }

    const newSessionDataV2 = {
      id: uuidv4(),
      coaches: [],
      players: [],
      createdBy: [
        {
          id: loggedInUserId,
          email: userEmail,
          name: userName,
          utrRating: '',
          ustaRating: '',
        },
      ],
      matchType,
      score,
      durationHours,
      durationMinutes,
      courtLocation: courtLocation['Location Name'],
      courtName: courtLocation['Location Name'],
      courtId: courtLocation.id,
      notes,
      tags,
      datetime,
    };
    const sessionResult = getTennisMatchResult(score)
    const winnerFromScore = sessionResult.winner;
    newSessionDataV2.score = sessionResult.score;

    players.forEach((player) => {
      newSessionDataV2.players.push({
        id: player.id,
        name: player.displayName,
        type: winnerFromScore === 0 ? 'winner' : 'opponent',
        utrRating: '',
        ustaRating: '',
      });
    });

    opponents.forEach((opponent) => {
      newSessionDataV2.players.push({
        id: opponent.id,
        name: opponent.displayName,
        type: winnerFromScore === 1 ? 'winner' : 'opponent',
        utrRating: '',
        ustaRating: '',
      });
    });

    coaches.forEach((coach) => {
      newSessionDataV2.coaches.push({
        id: coach.id,
        name: coach.displayName,
      });
    });

    console.log('new session data, v2', newSessionDataV2);

    console.log('newJournalEntryData', newJournalEntryData);
    if (isExistingJournalEntry) {
      await updateJournalEntryById({
        id: journalId,
        attributes: newJournalEntryData
      });
    }
    else {
      newJournalEntryData.id = uuidv4();
      if (enablePostMatchSection) {
        await createPlay(newSessionDataV2);
      }
      await createJournalEntry(newJournalEntryData);
      // window.location.href = `/v2/journal/view/${newJournalEntryData.id}`;
    }
    window.location.href = `/v2/journal`;

    // setIsLoading(false);
    setIsFormSubmitting(false);

  };

  return (
    <>
      <Helmet>
        <title>New Session | Playgrade</title>
      </Helmet>

      <Container>
        {isLoading ? (
          // Render loading indicator
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 'calc(100vh - 64px)', // Adjust this value based on your layout
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Stack
              direction={!isDesktop ? 'column' : 'row'}
              alignItems={!isDesktop ? 'stretch' : 'center'}
              justifyContent="space-between"
              mb={5}
              spacing={!isDesktop ? 2 : 0}
            >
              <Typography variant="h4" gutterBottom>
                {/* {props.type || 'Competitive'} Session */}
                {isExistingJournalEntry ? `Existing` : `New`} Journal Entry
              </Typography>
              {/* <Label marginLeft={6} sx={{ cursor: 'pointer' }} color={'error'} onClick={() => handleRequestFeedbackPopupOpen('12')}>Request</Label> */}
              <Modal
                keepMounted
                open={requestFeedbackPopupOpen}
                onClose={handleRequestFeedbackPopupClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
              >
                <Box sx={modalStyle}>
                  <Typography variant="h6" gutterBottom>
                    Link copied!
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    Since your opponent is not a registered user, you can share this link with them to request feedback.
                  </Typography>
                </Box>
              </Modal>
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={isCoachMode}
                    onChange={handleCoachModeToggle}
                    color="primary"
                  />
                }
                label="I am a coach"
              /> */}
              {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            Add Session
          </Button> */}
            </Stack>

            <Box sx={{ p: 2 }}>
              <form onSubmit={handleSubmit}>
                <Stack
                  direction={!isDesktop ? 'column' : 'row'}
                  alignItems={!isDesktop ? 'stretch' : 'center'}
                  justifyContent="space-between"
                  mb={1}
                  spacing={!isDesktop ? 0 : 2}
                >
                  <Autocomplete
                    multiple
                    value={players}
                    onChange={handlePlayerChange}
                    inputValue={playersInputValue}
                    onInputChange={(event, newInputValue) => {
                      setPlayersInputValue(newInputValue);
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;

                      if (inputValue === '') {
                        return [];
                      }

                      const isExisting = options.some(
                        (option) =>
                          inputValue === option.displayName ||
                          inputValue === option.inputValue
                      );

                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          id: inputValue,
                          displayName: `+ ${inputValue}`,
                          inputValue,
                          newUser: true
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-player"
                    options={allUsers}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      return option.displayName;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.displayName}</li>}
                    sx={{ width: isDesktop ? '50%' : '100%', borderColor: isPlayersValid ? 'initial' : 'red' }}
                    freeSolo
                    renderInput={(params) => <TextField {...params} label="Player(s)" />}
                  />
                  {(!isPlayersValid) && (
                    <Typography variant="body2" color="error">
                      Please select at least one player.
                    </Typography>
                  )}

                  <Autocomplete
                    multiple
                    value={opponents}
                    onChange={handleOpponentsChange}
                    inputValue={opponentsInputValue}
                    onInputChange={(event, newInputValue) => {
                      setOpponentsInputValue(newInputValue);
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;

                      if (inputValue === '') {
                        return [];
                      }

                      const isExisting = options.some(
                        (option) =>
                          inputValue === option.displayName ||
                          inputValue === option.inputValue
                      );

                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          id: inputValue,
                          displayName: `+ ${inputValue}`,
                          inputValue,
                          newUser: true,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-opponents"
                    options={allUsers}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      return option.displayName;
                    }}
                    renderOption={(props, option) => <li {...props}>{option.displayName}</li>}
                    sx={{ width: isDesktop ? '50%' : '100%', borderColor: isOpponentsValid ? 'initial' : 'red' }}
                    freeSolo
                    // disabled={isExistingJournalEntry}
                    renderInput={(params) => <TextField {...params} label="Opponent(s)" />}
                  />
                  {(!isOpponentsValid) && (
                    <Typography variant="body2" color="error">
                      Please select at least one opponent.
                    </Typography>
                  )}

                  {!isCoachMode && (
                    <Autocomplete
                      multiple
                      value={coaches}
                      onChange={handleCoachesChange}
                      inputValue={coachesInputValue}
                      onInputChange={(event, newInputValue) => {
                        setCoachesInputValue(newInputValue);
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        const { inputValue } = params;

                        if (inputValue === '') {
                          return [];
                        }

                        const isExisting = options.some(
                          (option) =>
                            inputValue === option.displayName ||
                            inputValue === option.inputValue
                        );

                        if (inputValue !== '' && !isExisting) {
                          filtered.push({
                            id: inputValue,
                            displayName: `+ ${inputValue}`,
                            inputValue,
                            newUser: true,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-solo-coaches"
                      options={allUsers}
                      getOptionLabel={(option) => {
                        if (typeof option === 'string') {
                          return option;
                        }
                        return option.displayName;
                      }}
                      renderOption={(props, option) => <li {...props}>{option.displayName}</li>}
                      sx={{ width: isDesktop ? '50%' : '100%' }}
                      freeSolo
                      // disabled={isExistingJournalEntry}
                      renderInput={(params) => <TextField {...params} label="Coach(s)" />}
                    />
                  )}
                </Stack>

                <Stack
                  direction={!isDesktop ? 'column' : 'row'}
                  alignItems={!isDesktop ? 'stretch' : 'center'}
                  justifyContent="space-between"
                  mb={1}
                  spacing={!isDesktop ? 0 : 2}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <MobileDateTimePicker
                      label='Date and Time'
                      value={datetime}
                      sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                      onChange={handleDateChange}
                      disabled={isExistingJournalEntry}
                    />
                  </LocalizationProvider>

                  <Autocomplete
                    disablePortal
                    id="combo-box-court"
                    options={allCourtsDataFromLocal}
                    groupBy={(option) => `${option.Area || ''}, ${option.City || ''}`}
                    getOptionLabel={(option) => option['Location Name']}
                    value={courtLocation}
                    onChange={handleCourtLocationChange}
                    inputValue={courtLocationInputValue}
                    onInputChange={(event, newInputValue) => {
                      setCourtLocationInputValue(newInputValue);
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;

                      if (inputValue === '') {
                        return [];
                      }

                      const isExisting = options.some(
                        (option) => inputValue === option['Location Name']
                      );

                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          id: uuidv4(),
                          'Location Name': `+ ${inputValue}`,
                          newLocation: true,
                          createdByUserId: loggedInUserId
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    fullWidth
                    sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                    renderInput={(params) => <TextField {...params} label="Court Location" />}
                    disabled={isExistingJournalEntry}
                  />

                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={isExistingJournalEntry ? existingJournalEntry.courtType : courtType}
                    options={['Hard', 'Clay', 'Grass']}
                    onChange={handleCourtTypeChange}
                    fullWidth
                    sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                    renderInput={(params) => <TextField {...params} label="Court Type" />}
                    required
                  />

                </Stack>
                <Typography variant="h6" color={"primary"}>Pre-match</Typography>
                <Typography variant="body2">Strategies for the upcoming session. You are able to add feedback and follow-up items after the match</Typography>
                <Button startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleAddStrategy}>Add Strategy</Button>
                <br />
                <Stack
                  direction={!isDesktop ? 'column' : 'row'}
                  alignItems={!isDesktop ? 'stretch' : 'center'}
                  justifyContent="space-between"
                  mb={1}
                  spacing={!isDesktop ? 0 : 2}
                >
                  <Grid container spacing={1}>
                    {
                      strategies.map((index) => (
                        <Grid item xs={12} sm={6} key={index}>
                          <TextField
                            label={`Strategy ${index + 1}`}
                            value={strategyNotes[index].title || ''}
                            onChange={(event) => handleStrategyNotesTitleChange(index, event.target.value)}
                            multiline
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            InputProps={{
                              style: { whiteSpace: 'pre-line' } // Allows text wrapping
                            }}
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Stack>
                <Typography variant="body2">Focus areas for the upcoming session. You are able to add feedback and follow-up items after the match</Typography>
                <Button startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleAddFocusArea}>Add Focus Area</Button>
                <br />
                <Stack
                  direction={!isDesktop ? 'column' : 'row'}
                  alignItems={!isDesktop ? 'stretch' : 'center'}
                  justifyContent="space-between"
                  mb={1}
                  spacing={!isDesktop ? 0 : 2}
                >
                  <Grid container spacing={1}>
                    {
                      focusAreas.map((index) => (
                        <Grid item xs={12} sm={6} key={index}>
                          <TextField
                            label={`Focus Area ${index + 1}`}
                            value={focusAreaNotes[index].title || ''}
                            onChange={(event) => handleFocusAreasTitleChange(index, event.target.value)}
                            multiline
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            InputProps={{
                              style: { whiteSpace: 'pre-line' } // Allows text wrapping
                            }}
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Stack>
                <Typography variant="body2">What shots and shot patterns do you want to focus on in this session? You are able to rate them after the session</Typography>
                <Stack
                  direction={!isDesktop ? 'column' : 'row'}
                  alignItems={!isDesktop ? 'stretch' : 'center'}
                  justifyContent="space-between"
                  mb={1}
                  spacing={!isDesktop ? 0 : 2}
                >
                  {/* <Autocomplete
                    multiple
                    id="tags"
                    options={drillOptions}
                    getOptionLabel={(option) => option.label}
                    value={tags}
                    onChange={handleTagsChange}
                    sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '100%' : '100%' }}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <div>
                          <strong>{option.label}</strong>
                          <div>{option.description}</div>
                        </div>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Drills" placeholder="Add drills" fullWidth />
                    )}
                  /> */}
                  {/* <Autocomplete
                    multiple
                    id="focus-areas"
                    options={focusAreasOptions}
                    sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '100%' : '100%' }}
                    value={focusAreas}
                    onChange={handleFocusAreasChange}
                    renderInput={(params) => <TextField {...params} label="Focus Areas" fullWidth />}
                  /> */}
                  <Autocomplete
                    multiple
                    id="shotPatterns"
                    options={shotPatternOptions}
                    getOptionLabel={(option) => option.label}
                    value={shotPatterns}
                    onChange={handleShotPatternsChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    fullWidth
                    renderOption={(props, option) => (
                      <li {...props}>
                        <div>
                          <strong>{option.label}</strong>
                          <div>{option.description}</div>
                        </div>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Shots/Patterns" placeholder="Select shots or patterns to work on" fullWidth />
                    )}
                  />


                </Stack>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={enablePostMatchSection}
                      onChange={handlePostMatchsectionToggle}
                      color="primary"
                    />
                  }
                  label="I finished the session"
                />
                <Typography variant="h6" color={"primary"}>Post-match</Typography>
                <Typography variant="body2">How did the session go? Evaluate different aspects of your game.</Typography>
                {enablePostMatchSection && <>
                  <Stack
                    direction={!isDesktop ? 'column' : 'row'}
                    alignItems={!isDesktop ? 'stretch' : 'center'}
                    justifyContent="space-between"
                    mb={1}
                    spacing={!isDesktop ? 0 : 2}
                  >
                    <TextField
                      label="Final Score"
                      value={score}
                      onChange={handleScoreChange}
                      fullWidth
                      required
                      InputProps={
                        {
                          placeholder: 'e.g. 7-6, 6-2'
                        }
                      }
                      sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                    />
                    <TextField
                      label="Number of Aces"
                      value={numberOfAces}
                      onChange={handleNumberOfAcesChange}
                      fullWidth
                      sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                    />

                    <TextField
                      label="Duration (Hours)"
                      value={durationHours}
                      onChange={handleDurationHoursChange}
                      type="number"
                      sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                      fullWidth
                      inputProps={{ min: 0 }}
                      required
                    />

                    <TextField
                      label="Duration (Minutes)"
                      value={durationMinutes}
                      fullWidth
                      onChange={handleDurationMinutesChange}
                      type="number"
                      inputProps={{ min: 0, max: 59 }}
                      sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                      required
                    />
                  </Stack>
                  {shotPatterns.length !== 0 && <Typography variant="body2">How was your performance for these shot patterns?</Typography>}
                  <Stack
                    direction={!isDesktop ? 'column' : 'row'}
                    alignItems={!isDesktop ? 'stretch' : 'center'}
                    justifyContent="space-between"
                    mb={1}
                    spacing={!isDesktop ? 0 : 4}
                  >
                    <Grid container spacing={1}>
                      {shotPatterns.map((shotPattern) => (
                        <Grid item xs={12} sm={4} key={shotPattern.label}>
                          <HoverRating name={shotPattern.label} patternId={shotPattern.id} shotPatternRatings={shotPatternRatings}
                            setShotPatternRatings={setShotPatternRatings} />
                        </Grid>
                      ))}
                    </Grid>

                  </Stack>
                  {
                    strategies.map((index) => (
                      <>
                        <Typography variant="body2">{`Strategy ${index + 1} : ${strategyNotes[index].title}`}</Typography>
                        <Stack
                          direction={!isDesktop ? 'column' : 'row'}
                          alignItems={!isDesktop ? 'stretch' : 'center'}
                          justifyContent="space-between"
                          key={index}
                          mb={1}
                          spacing={!isDesktop ? 0 : 2}>
                          <TextField
                            key={index}
                            label={`Feedback`}
                            value={strategyNotes[index].feedback || ''}
                            onChange={(event) => handleStrategyNotesFeedbackChange(index, event.target.value)}
                            multiline
                            sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                          />
                          <TextField
                            key={index}
                            label={`Follow-up`}
                            value={strategyNotes[index].followup || ''}
                            onChange={(event) => handleStrategyNotesFollowupChange(index, event.target.value)}
                            multiline
                            sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                          />
                        </Stack>
                      </>
                    ))
                  }

                  {
                    focusAreas.map((index) => (
                      <>
                        <Typography variant="body2">{`Focus Area ${index + 1} : ${focusAreaNotes[index].title}`}</Typography>
                        <Stack
                          direction={!isDesktop ? 'column' : 'row'}
                          alignItems={!isDesktop ? 'stretch' : 'center'}
                          justifyContent="space-between"
                          key={index}
                          mb={1}
                          spacing={!isDesktop ? 0 : 2}>
                          <TextField
                            key={index}
                            label={`Feedback`}
                            value={focusAreaNotes[index].feedback || ''}
                            onChange={(event) => handleFocusAreaNotesFeedbackChange(index, event.target.value)}
                            multiline
                            sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                          />
                          <TextField
                            key={index}
                            label={`Follow-up`}
                            value={focusAreaNotes[index].followup || ''}
                            onChange={(event) => handleFocusAreaNotesFollowupChange(index, event.target.value)}
                            multiline
                            sx={{ marginTop: 1, marginBottom: 1, width: isDesktop ? '50%' : '100%' }}
                          />
                        </Stack>
                      </>
                    ))
                  }

                  {/* <Stack
                    direction={!isDesktop ? 'column' : 'row'}
                    alignItems={!isDesktop ? 'stretch' : 'center'}
                    justifyContent="space-between"
                    mt={focusAreas ? 2 : 0}
                    mb={1}
                    spacing={!isDesktop ? 0 : 2}
                  >
                    {focusAreas.map((focusArea) => (
                      <TextField
                        key={focusArea}
                        label={`Notes for ${focusArea}`}
                        value={focusAreaNotes[focusArea] || ''}
                        onChange={(event) => handleFocusAreaNotesChange(focusArea, event.target.value)}
                        multiline
                        sx={{ marginTop: 1 }}
                        fullWidth
                      />
                    ))}
                  </Stack> */}

                  <TextField
                    label="Key things to improve"
                    value={notes}
                    onChange={handleNotesChange}
                    fullWidth
                    InputProps={{
                      placeholder: 'e.g. Played well, but need to work on my serve'
                    }}
                    sx={{ marginTop: focusAreas ? 1 : 0, marginBottom: focusAreas ? 1 : 0 }}
                    multiline
                    rows={4}
                  /></>}


                <Button type="submit" variant="contained" sx={{ mt: 2 }} disabled={isFormSubmitting}>
                  {isFormSubmitting ? <CircularProgress size={24} /> : isExistingJournalEntry ? 'Update' : 'Save'}
                </Button>

              </form>
            </Box>
          </>
        )}
      </Container >
    </>
  );
}