import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useEffect, useState, useContext } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
// @mui
import { useTheme } from '@mui/material/styles';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { Grid, Container, Tooltip, IconButton, Autocomplete, Box, TextField, Button, Stack, CircularProgress } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { AuthContext } from '../utils/auth';
import { WanderingAround } from './UnauthenticatedView';
import useResponsive from '../hooks/useResponsive';
import { invertScore } from './SelfFeedbackForm';
import { getUserByEmailOrPhone } from '../utils/getUser';
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const isDesktop = useResponsive('up', 'lg');
  const theme = useTheme();
  const [matches, setMatches] = useState([]);
  const [filteredMatches, setFilteredMatches] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [currentUserId, setCurrentUserId] = useState('');
  const [matchTypePieChart, setMatchTypePieChart] = useState([]);
  const [courtTimeDistribution, setCourtTimeDistribution] = useState([]);
  const [matchTally, setMatchTally] = useState('');
  const [setsTally, setSetsTally] = useState('');
  const [gamesTally, setGamesTally] = useState('');
  const [totalPlayTime, setTotalPlayTime] = useState('');
  const [feedbacks, setFeedbacks] = useState([]);
  const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
  const feedbackAreas = ["forehand", "backhand", "volley", "slice", "serve", "return", "courtCoverage", "stamina"]
  const defaultFeedbackOptions = { "Limited": 0, "Developing": 1, "Average": 2, "Proficient": 3, "Outstanding": 4 }
  const [globalSelfFeedbackAreaAverages, setGlobalSelfFeedbackAreaAverages] = useState({});
  const [selfFeedbackAreaHighestValues, setSelfFeedbackAreaHighestValues] = useState({});
  const [globalOppGivFeedbackAreaAverages, setGlobalOppGivFeedbackAreaAverages] = useState({});
  const [oppgivFeedbackAreaHighestValues, setOppGivFeedbackAreaHighestValues] = useState({});
  const [globalOppRecFeedbackAreaAverages, setGlobalOppRecFeedbackAreaAverages] = useState({});
  const [globalCoachRecFeedbackAreaAverages, setGlobalCoachRecFeedbackAreaAverages] = useState({});
  const [opprecFeedbackAreaHighestValues, setOppRecFeedbackAreaHighestValues] = useState({});
  const [tagDistribution, setTagDistribution] = useState([]);
  const [filtersMetadata, setFiltersMetadata] = useState({});
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState({
    startDateTime: null,
    endDateTime: null,
    opponent: '',
    location: '',
    tag: '',
  });


  useEffect(() => {
    const allTags = [];
    const allLocations = [];
    const allOpponents = [];
    const allOpponentUstaRatings = [];
    const allOpponentUtrRatings = [];

    matches.forEach((match) => {
      const playersExcludingCurrentUser = match.players
        .filter(player => player.id !== currentUserId);

      playersExcludingCurrentUser.forEach(player => {
        if (!allOpponents.includes(player.name)) {
          allOpponents.push(player.name);
        }
      });

      if (match.tags) {
        match.tags.forEach((tag) => {
          if (!allTags.includes(tag)) {
            allTags.push(tag);
          }
        });
      }

      if (!allLocations.includes(match.courtLocation)) {
        allLocations.push(match.courtLocation);
      }

      // Check if opponentUstaRating is present and not already added
      const opponentUstaRating = playersExcludingCurrentUser.find(player => player.type === 'opponent')?.ustaRating;
      if (opponentUstaRating && !allOpponentUstaRatings.includes(opponentUstaRating)) {
        allOpponentUstaRatings.push(opponentUstaRating);
      }

      // Check if opponentUtrRating is present and not already added
      const opponentUtrRating = playersExcludingCurrentUser.find(player => player.type === 'opponent')?.utrRating;
      if (opponentUtrRating && !allOpponentUtrRatings.includes(opponentUtrRating)) {
        allOpponentUtrRatings.push(opponentUtrRating);
      }
    });

    setFiltersMetadata({
      tags: allTags,
      courtLocations: allLocations,
      opponents: allOpponents,
      opponentUstaRating: allOpponentUstaRatings,
      opponentUtrRating: allOpponentUtrRatings,
    });

    setFilteredMatches(matches);
    console.log(filtersMetadata);
  }, [matches, currentUserId]);




  useEffect(() => {
    setFilteredFeedbacks(feedbacks);
  }, [feedbacks]);

  useEffect(() => {
    // Move the filtering logic here, whenever any filter field changes
    const filtered = matches.filter((match) => {
      const matchDateTime = new Date(match.datetime);
      const { startDateTime, endDateTime, opponent, location, tag } = filters;
      let isMatched = true;

      if (startDateTime && matchDateTime < new Date(startDateTime)) {
        isMatched = false;
      }
      if (endDateTime && matchDateTime > new Date(endDateTime)) {
        isMatched = false;
      }

      // Updated opponent comparison to check against opponent names
      if (opponent && !match.players.some(player => player.name.includes(opponent))) {
        isMatched = false;
      }

      if (location && match.courtLocation !== location) {
        isMatched = false;
      }
      if (tag && (!match.tags || !match.tags.includes(tag))) {
        isMatched = false;
      }

      return isMatched;
    });

    setFilteredMatches(filtered);

    const filteredPlayIds = filtered.map((match) => match.id);
    const filteredFeedbacks = feedbacks.filter((feedback) =>
      filteredPlayIds.includes(feedback.play.id)
    );

    setFilteredFeedbacks(filteredFeedbacks);
  }, [matches, feedbacks, filters]);

  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };


  const applyFilters = () => {
    const filtered = matches.filter((match) => {
      const matchDateTime = new Date(match.datetime);
      const { startDateTime, endDateTime, opponent, location, tag } = filters;
      let isMatched = true;
      if (startDateTime && matchDateTime < new Date(startDateTime)) {
        isMatched = false;
      }
      if (endDateTime && matchDateTime > new Date(endDateTime)) {
        isMatched = false;
      }
      if (opponent && match.opponent !== opponent) {
        isMatched = false;
      }
      if (location && match.courtLocation !== location) {
        isMatched = false;
      }
      if (tag && (!match.tags || !match.tags.includes(tag))) {
        isMatched = false;
      }
      return isMatched;
    });
    setFilteredMatches(filtered);
    const filteredPlayIds = filtered.map((match) => match.id);
    const filteredFeedbacks = feedbacks.filter((feedback) =>
      filteredPlayIds.includes(feedback.playId)
    );
    setFilteredFeedbacks(filteredFeedbacks);
  };


  const matchTypePieChartData = filteredMatches.reduce((acc, match) => {
    const matchType = match.matchType;
    if (acc[matchType]) {
      acc[matchType] += 1;
    } else {
      acc[matchType] = 1;
    }
    return acc;
  }, {});

  const getCourtTimeDistribution = filteredMatches.reduce((acc, match) => {
    const courtLocation = match.courtLocation;
    const durationHours = Number(match.durationHours);
    const durationMinutes = Number(match.durationMinutes);
    const totalTime = Math.round(((durationHours * 60 + durationMinutes) / 60) * 100) / 100;
    if (acc[courtLocation]) {
      acc[courtLocation] += totalTime;
    } else {
      acc[courtLocation] = totalTime;
    }
    return acc;
  }, {});

  const getTotalPlayTimeHoursAndMinutes = filteredMatches.reduce((acc, match) => {
    const durationHours = Number(match.durationHours);
    const durationMinutes = Number(match.durationMinutes);
    acc.totalHours += durationHours;
    acc.totalMinutes += durationMinutes;
    return acc;
  }, { totalHours: 0, totalMinutes: 0 });

  const matchToSets = (score) => {
    if (!score) {
      return { playerOneGames: 0, playerTwoGames: 0, playerOneWins: 0, playerTwoWins: 0 }
    }
    const sets = score.split(',');
    let totalPlayerOneGames = 0;
    let totalPlayerTwoGames = 0;
    const setResults = sets.map((set) => {
      const withoutTieBreak = set.split('(')[0];
      const games = withoutTieBreak.split('-');
      const playerOneGames = Number(games[0]);
      totalPlayerOneGames += playerOneGames;
      const playerTwoGames = Number(games[1]);
      totalPlayerTwoGames += playerTwoGames;
      if (playerOneGames > playerTwoGames) {
        return 'win';
      }
      return 'loss';
    });
    const playerOneWins = setResults.filter((result) => result === 'win').length;
    const playerTwoWins = setResults.filter((result) => result === 'loss').length;
    return { playerOneGames: totalPlayerOneGames, playerTwoGames: totalPlayerTwoGames, playerOneWins, playerTwoWins };
  };

  const getTagsFromMatches = (match) => {
    if (!match.tags) {
      return [];
    }
    return match.tags
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loggedInUserId = await getUserByEmailOrPhone(currentUser);
        setCurrentUserId(loggedInUserId)
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData(); // Call the async function immediately

  }, [currentUser, currentUserId]);

  useEffect(() => {
    const matchTypePieChartArray = Object.keys(matchTypePieChartData).map((matchType) => {
      return { label: matchType, value: matchTypePieChartData[matchType] };
    });
    setMatchTypePieChart(matchTypePieChartArray);
    const courtTimeDistributionArray = Object.keys(getCourtTimeDistribution).map((courtLocation) => {
      return { label: courtLocation, value: getCourtTimeDistribution[courtLocation] };
    });
    const totalCourtTime = Math.round(((getTotalPlayTimeHoursAndMinutes.totalHours * 60 + getTotalPlayTimeHoursAndMinutes.totalMinutes) / 60) * 100) / 100;
    courtTimeDistributionArray.sort((a, b) => (a.value > b.value) ? -1 : 1);
    setCourtTimeDistribution(courtTimeDistributionArray);
    const getMatchTally = filteredMatches.reduce((acc, match) => {
      const matchScore = currentUserId !== match.opponentId ? match.score : invertScore(match.score)
      const { playerOneWins, playerTwoWins } = matchToSets(matchScore);
      if (playerOneWins > playerTwoWins) {
        acc.wins += 1;
      }
      if (playerOneWins < playerTwoWins) {
        acc.losses += 1;
      }
      return acc;
    }, { wins: 0, losses: 0 });
    const getSetsTally = filteredMatches.reduce((acc, match) => {
      const matchScore = currentUserId !== match.opponentId ? match.score : invertScore(match.score)
      const { playerOneWins, playerTwoWins } = matchToSets(matchScore);
      console.log('matchtosets', matchToSets(matchScore));
      acc.wins += playerOneWins;
      acc.losses += playerTwoWins;
      return acc;
    }, { wins: 0, losses: 0 });
    const getGamesTally = filteredMatches.reduce((acc, match) => {
      // Check if the score is null or an empty string
      if (match.score !== null && match.score !== '') {
        const matchScore = currentUserId !== match.opponentId ? match.score : invertScore(match.score);
        const { playerOneGames, playerTwoGames } = matchToSets(matchScore);
        acc.wins += playerOneGames;
        acc.losses += playerTwoGames;
      }
      return acc;
    }, { wins: 0, losses: 0 });
    const tagDistributionArray = filteredMatches.reduce((acc, match) => {
      const tags = getTagsFromMatches(match);
      tags.map((tag) => {
        if (!tag) {
          return 0;
        }
        if (acc[tag]) {
          acc[tag] += 1;
        } else {
          acc[tag] = 1;
        }
        return 0
      });
      return acc;
    }, {});
    setMatchTally(`${getMatchTally.wins}-${getMatchTally.losses}`)
    setSetsTally(`${getSetsTally.wins}-${getSetsTally.losses}`)
    setGamesTally(`${getGamesTally.wins}-${getGamesTally.losses}`)
    setTotalPlayTime(totalCourtTime);
    const tagsForPieChart = Object.keys(tagDistributionArray).map((tag) => {
      return { label: tag, value: tagDistributionArray[tag] };
    });
    setTagDistribution(tagsForPieChart);
  }, [filteredMatches]);

  const formatFeedback = (feedback, feedbackType) => {
    let type = ''
    if (feedback.createdFor.userId === currentUserId && currentUserId === feedback.createdBy.userId) {
      type = 'Self'
    }
    if (feedback.createdFor.userId === currentUserId && feedback.feedbackType === 'peer') {
      type = 'Opponent Received'
    }
    if (feedback.createdFor.userId === currentUserId && feedback.feedbackType === 'coach') {
      type = 'Coach'
    }
    if (feedback.createdBy.userId === currentUserId && feedback.type === 'OpponentGiven') {
      type = 'Opponent Given'
    }
    const filteredFeedback = Object.keys(feedback.feedback).filter((key) => feedbackAreas.includes(key) && type === feedbackType);
    const formattedFeedback = filteredFeedback.reduce((acc, key) => {
      acc[key] = defaultFeedbackOptions[feedback.feedback[key]];
      return acc;
    }, {});
    console.log('formattedFeedback', type, formattedFeedback)
    return formattedFeedback;
  }

  useEffect(() => {
    const formattedSelfFeedbacks = filteredFeedbacks.map((feedback) => {
      return formatFeedback(feedback, 'Self');
    });
    const formattedOppGivenFeedbacks = filteredFeedbacks.map((feedback) => {
      return formatFeedback(feedback, 'Opponent Given');
    });
    const formattedOppReceivedFeedbacks = filteredFeedbacks.map((feedback) => {
      return formatFeedback(feedback, 'Opponent Received');
    });
    const coachReceivedFeedbacks = filteredFeedbacks.map((feedback) => {
      return formatFeedback(feedback, 'Coach')
    })
    console.log('formattedSelfFeedbacks', formattedSelfFeedbacks)
    console.log('formattedOppGivenFeedbacks', formattedOppGivenFeedbacks)
    console.log('formattedOppReceivedFeedbacks', formattedOppReceivedFeedbacks)
    console.log('coachReceivedFeedbacks', coachReceivedFeedbacks)
    const crunchFeedbackNumbers = (feedbacksInput) => {
      const nonEmptyFeedbacks = feedbacksInput.filter(feedback => Object.keys(feedback).length > 0);

      if (nonEmptyFeedbacks.length === 0) {
        return { 'max': {}, 'avg': {} }; // No non-empty feedback, return empty result
      }

      const totalFeedbackAreas = nonEmptyFeedbacks.reduce((acc, feedback) => {
        feedbackAreas.forEach((area) => {
          acc[area] = (acc[area] || 0) + feedback[area];
        });
        return acc;
      }, {});

      const averageFeedbackAreas = feedbackAreas.reduce((acc, area) => {
        acc[area] = Math.round((totalFeedbackAreas[area] / nonEmptyFeedbacks.length) * 100) / 100 || 0;
        return acc;
      }, {});

      const highestFeedbackAreas = nonEmptyFeedbacks.reduce((acc, feedback) => {
        feedbackAreas.forEach((area) => {
          if (acc[area]) {
            acc[area] = Math.max(acc[area], feedback[area]);
          } else {
            acc[area] = feedback[area];
          }
        });
        return acc;
      }, {});

      return { 'max': highestFeedbackAreas, 'avg': averageFeedbackAreas };
    };

    setGlobalSelfFeedbackAreaAverages(crunchFeedbackNumbers(formattedSelfFeedbacks).avg);
    setSelfFeedbackAreaHighestValues(crunchFeedbackNumbers(formattedSelfFeedbacks).max);
    console.log('crunchFeedbackNumbers(formattedSelfFeedbacks).avg', crunchFeedbackNumbers(formattedSelfFeedbacks).avg)
    setGlobalOppGivFeedbackAreaAverages(crunchFeedbackNumbers(formattedOppGivenFeedbacks).avg);
    setOppGivFeedbackAreaHighestValues(crunchFeedbackNumbers(formattedOppGivenFeedbacks).max);
    setGlobalCoachRecFeedbackAreaAverages(crunchFeedbackNumbers(coachReceivedFeedbacks).avg);
    setGlobalOppRecFeedbackAreaAverages(crunchFeedbackNumbers(formattedOppReceivedFeedbacks).avg);
    setOppRecFeedbackAreaHighestValues(crunchFeedbackNumbers(formattedOppReceivedFeedbacks).max);
  }, [filteredFeedbacks]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentUser) {
          // Fetch user ID
          const userIdFromResponse = await getUserByEmailOrPhone(currentUser);

          // Fetch matches
          const matchesConfig = {
            method: "get",
            url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/plays/users/${userIdFromResponse}`,
            headers: {
              "Content-Type": "application/json",
            },
          };
          const matchesResponse = await axios(matchesConfig);
          setMatches(matchesResponse.data);

          // Fetch feedbacks
          const feedbacksConfig = {
            method: "get",
            url: `https://jtrsg72u4k.execute-api.us-west-2.amazonaws.com/production/feedbacks/users/${userIdFromResponse}`,
            headers: {
              "Content-Type": "application/json",
            },
          };
          const feedbacksResponse = await axios(feedbacksConfig);
          setFeedbacks(feedbacksResponse.data);
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  if (!currentUser) {
    return <WanderingAround />;
  }

  const handleToolTipToggle = () => {
    setFiltersOpen(!filtersOpen);
  };


  return (
    <>
      <Helmet>
        <title> Dashboard | Playgrade </title>
      </Helmet>

      <Container maxWidth="xl">
        {isLoading ? (
          // Render loading indicator
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 'calc(100vh - 64px)', // Adjust this value based on your layout
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            {/* <Typography variant="h4" sx={{ mb: 5 }}>
          Your sessions in numbers
        </Typography> */}
            <Tooltip title="Filter list" onClick={handleToolTipToggle} sx={{ float: 'right' }}>
              <IconButton>
                <Iconify icon="ic:round-filter-list" />
              </IconButton>
            </Tooltip>
            <Box
              mb={filtersOpen ? 3 : 0}
              style={{
                height: filtersOpen ? 'auto' : 0,
                display: filtersOpen ? 'block' : 'none',
                overflow: 'hidden',
                transition: 'height 0.3s ease-in-out',
              }}
            >
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <MobileDateTimePicker
                      label='Start date'
                      fullWidth
                      value={filters.startDateTime}
                      sx={{ marginTop: 1, marginBottom: 1, width: 300 }}
                      onChange={(date) => handleFilterChange('startDateTime', date)}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <MobileDateTimePicker
                      label='End date'
                      fullWidth
                      value={filters.endDateTime}
                      sx={{ marginTop: 1, marginBottom: 1, width: 300 }}
                      onChange={(date) => handleFilterChange('endDateTime', date)}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={filtersMetadata.opponents}
                    onChange={(event, value) => handleFilterChange('opponent', value)}
                    fullWidth
                    sx={{ marginTop: 1, marginBottom: 1, width: 300, marginRight: isDesktop ? 1 : 0 }}
                    renderInput={(params) => <TextField {...params} label="Opponent" />}
                    required
                  />
                </Grid>

                <Grid item>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={filtersMetadata.opponentUstaRating}
                    onChange={(event, value) => handleFilterChange('opponentUstaRating', value)}
                    fullWidth
                    sx={{ marginTop: 1, marginBottom: 1, width: 300, marginRight: isDesktop ? 1 : 0 }}
                    renderInput={(params) => <TextField {...params} label="Opponent USTA Rating" />}
                  />
                </Grid>

                <Grid item>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={filtersMetadata.opponentUtrRating}
                    onChange={(event, value) => handleFilterChange('opponentUtrRating', value)}
                    fullWidth
                    sx={{ marginTop: 1, marginBottom: 1, width: 300, marginRight: isDesktop ? 1 : 0 }}
                    renderInput={(params) => <TextField {...params} label="Opponent UTR Rating" />}
                  />
                </Grid>

                <Grid item>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={filtersMetadata.courtLocations}
                    onChange={(event, value) => handleFilterChange('location', value)}
                    fullWidth
                    sx={{ marginTop: 1, marginBottom: 1, width: 300, marginRight: isDesktop ? 1 : 0 }}
                    renderInput={(params) => <TextField {...params} label="Court" />}
                    required
                  />
                </Grid>
                <Grid item sx={{
                  right: 0,
                }}>
                  {/* <Button variant="contained" onClick={applyFilters}>
            Apply Filters
          </Button> */}
                </Grid>
              </Grid>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Match tally" total={matchTally} icon={'solar:tennis-bold'} />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Sets tally" total={setsTally} color="info" icon={'solar:tennis-bold'} />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Games tally" total={gamesTally} color="warning" icon={'solar:tennis-bold'} />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total playtime" total={`${totalPlayTime} hrs`} color="error" icon={'ri:time-fill'} />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <AppCurrentSubject
                  title="Feedback"
                  chartLabels={["Forehand", "Backhand", "Volley", "Slice", "Serve", "Return", "Court Coverage", "Stamina"]}
                  chartData={[
                    { name: 'Self', data: Object.values(globalSelfFeedbackAreaAverages) },
                    { name: 'Opponent', data: Object.values(globalOppRecFeedbackAreaAverages) },
                    { name: 'Coach', data: Object.values(globalCoachRecFeedbackAreaAverages) }
                  ]}
                  chartColors={[...Array(8)].map(() => theme.palette.text.secondary)}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <AppCurrentVisits
                  title="Tag distribution"
                  chartData={tagDistribution}
                  chartColors={[
                    theme.palette.primary.main,
                    theme.palette.info.main,
                    theme.palette.warning.main,
                    theme.palette.error.main,
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <AppConversionRates
                  title="Favorite Court Locations"
                  // subheader="(+43%) than last year"
                  chartData={courtTimeDistribution}
                  unit='hrs'
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <AppCurrentVisits
                  title="Types of sessions"
                  chartData={matchTypePieChart}
                  chartColors={[
                    theme.palette.primary.main,
                    theme.palette.info.main,
                    theme.palette.warning.main,
                    theme.palette.error.main,
                  ]}
                />
              </Grid>
            </Grid>
          </>)}
      </Container>
    </>
  );
}
