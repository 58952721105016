import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { AuthProvider } from './utils/auth';

// ----------------------------------------------------------------------

ReactGA.initialize('G-1YF828GVH2');
ReactGA.pageview(window.location.pathname + window.location.search);

export default function App() {
  return (
    <HelmetProvider>
      <AuthProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Router />
        </ThemeProvider>
      </BrowserRouter>
      </AuthProvider>
    </HelmetProvider>
  );
}
